<template>
  <!--todo: протестировать и поправить странциу (создать 404Layout)-->
  <div class="error404 d-flex justify-center align-center">
    <vue-headful
      title="Страница не найдена"
      description="Страница не найдена"
    />
    <div>Страница не найдена</div>
  </div>
</template>

<script>
export default {
  name: 'PageNotFound'
}
</script>

<style scoped>
.error404 {
  position: relative;
  width: 100%;
  height: 100vh;
  background: #fff;
}
</style>
